import {Component, OnInit, ViewEncapsulation} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {switchMap} from 'rxjs'
import {ROUTE_PARAM_TYPE} from '../../application/data-types'
import {ImageService} from '../../services/image.service'
import { SelectableTextComponent } from './selectable-text/selectable-text.component'

@Component({
    selector: 'jhc-conditions',
    templateUrl: './conditions.component.html',
    styleUrls: ['./conditions.component.scss'],
    // Note this is needed bc we load the content dynamically so the css won't apply if it's not in the main style.css
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [SelectableTextComponent]
})
export class ConditionsComponent implements OnInit {

  public type: 'terms' | 'privacy' | 'faq' | 'integritet' | 'villkor' = 'privacy'

  public content = '<h2>Hämtar...</h2>'

  constructor(
    private readonly route: ActivatedRoute,
    private readonly imageService: ImageService
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.type = params.get(ROUTE_PARAM_TYPE) as any
          return this.imageService.getText(this.type)
        })
      )
      .subscribe({
        next: (html: string) => this.content = html
      })

  }
}
