import {provideHttpClient} from '@angular/common/http'
import {APP_INITIALIZER, ApplicationConfig, enableProdMode} from '@angular/core'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation} from '@angular/router'
import {environment} from '../environments/environment'
import {LOCAL_STORAGE, SESSION_STORAGE} from './application/providers'
import {BootstrapService} from './services/bootstrap.service'
import {appRoutes} from './app.routes'

if (environment.production) {
  enableProdMode()
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: SESSION_STORAGE,
      useValue: sessionStorage
    },
    {
      provide: LOCAL_STORAGE,
      useValue: localStorage
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (s: BootstrapService) => () => s.bootstrap(),
      deps: [BootstrapService],
      multi: true
    },
    provideRouter(appRoutes, withHashLocation()),
    provideAnimationsAsync(),
    provideHttpClient()
  ]
}