import {Routes} from '@angular/router'

export default [
  {
    path: '',
    children: [
      {
        path: ':dialog',
        loadComponent: () => import('./test.component').then(c => c.TestComponent)
      }
    ]
  }] satisfies Routes
