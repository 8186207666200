import {Routes} from '@angular/router'
import {
  PATH_CONFIRMATION,
  PATH_CREATE,
  PATH_RESULT,
  PATH_SIGN,
  PATH_VERIFY,
  ROUTE_PARAM_EMAIL,
  ROUTE_PARAM_EMAIL_HASH,
  ROUTE_PARAM_PROCESS_ID
} from '../application/data-types'
import {MainComponent} from './main.component'

export default [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: PATH_CREATE,
        pathMatch: 'full'
      },
      {
        path: PATH_CREATE,
        loadComponent: () => import('./0-create/create.component').then(c => c.CreateComponent)
      },
      {
        path: `${PATH_VERIFY}/:${ROUTE_PARAM_PROCESS_ID}`,
        loadComponent: () => import('./1-verify/verify.component').then(c => c.VerifyComponent)
      },
      {
        path: `${PATH_CONFIRMATION}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL_HASH}`,
        loadComponent: () => import('./2-confirmation/confirmation.component').then(c => c.ConfirmationComponent)
      },
      {
        path: `${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}`,
        loadComponent: () => import('./3-sign/sign.component').then(c => c.SignComponent)
      },
      {
        path: `${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}/${PATH_RESULT}`,
        loadComponent: () => import('./4-result/result.component').then(c => c.ResultComponent)
      }
    ]
  }
] satisfies Routes
