import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import {ImageService} from '../services/image.service'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'

@Component({
    selector: 'jhc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements AfterViewInit {
  public imageUrl: string = ''

  @ViewChild('backgroundImage') private readonly backgroundHolder: ElementRef = new ElementRef<any>(undefined)

  constructor(
    private readonly imageService: ImageService,
    private readonly renderer: Renderer2,
    private readonly router: Router
  ) {
  }

  public ngAfterViewInit(): void {
    const images = [
      'seal_bg_1.jpg',
      'seal_bg_2.jpg',
      'seal_bg_3.jpg',
      'seal_bg_4.jpg',
      'seal_bg_5.jpg',
      'seal_bg_7.jpg',
      'seal_bg_8.jpg',
      'seal_bg_9.jpg',
      'seal_bg_10.jpg',
      'seal_bg_11.jpg',
      'seal_bg_12.jpg',
      'seal_bg_13.jpg',
      'seal_bg_14.jpg',
      'seal_bg_15.jpg'
    ]

    this.imageService.showBackground.subscribe({
      // Deal the Seal is not allowed to be showed in 'conditions' pages
      next: ((show: boolean) => this.showHideBackground(show && !this.router.url.startsWith('/conditions')))
    })

    const randomElement = images[Math.floor(Math.random() * images.length)]
    this.imageService.getImage(randomElement).subscribe({
      next: (url: string) => {
        this.imageUrl = `url(${url})`
        this.renderer.addClass(this.backgroundHolder.nativeElement, 'background-animation')
        this.imageService.showBackground.next(true)
      }
    })
  }

  private showHideBackground(show: boolean): void {
    if (show) {
      this.renderer.setStyle(this.backgroundHolder.nativeElement, 'background-image', `${this.imageUrl}`)
    }
    if (!show) {
      this.renderer.removeStyle(this.backgroundHolder.nativeElement, 'background-image')
      this.renderer.removeClass(this.backgroundHolder.nativeElement, 'background-animation')
    }
  }
}
