import {Component} from '@angular/core'
import { RouterOutlet } from '@angular/router'

@Component({
    selector: 'jhc-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class MainComponent {
}
